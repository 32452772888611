import { Link, Typography } from '@mui/material';
import SloganImage from 'assets/geniusee-slogan.png';
import colors from 'constants/colors';
import { List, ListItem, Section } from '../styles';

const links = [
  { label: 'AI-Powered App Development', href: 'https://geniusee.com/ai-powered-app-development' },
  { label: 'Consulting', href: 'https://geniusee.com/digital-transformation-consulting' },
  { label: 'Software Engineering Service', href: 'https://geniusee.com/software' },
  { label: 'Cyber Security Services', href: 'https://geniusee.com/security' },
  { label: 'Technical Support', href: 'https://geniusee.com/it-support' },
  { label: 'Product Design', href: 'https://geniusee.com/ui-ux-design' },
  { label: 'Team Augmentation', href: 'https://geniusee.com/outstaffing' },
];

export default function AdditionalInformation() {
  return (
    <Section style={{ background: colors.grey.light, border: 'none' }}>
      <Typography variant="h6" fontWeight="900">
        Geniusee
      </Typography>
      <br />
      <Typography fontWeight="600" mb="16px">
        Geniusee services
      </Typography>
      <List>
        {links.map((link) => (
          <ListItem key={link.label}>
            <Link href={link.href} target={'_blank'}>
              {link.label}
            </Link>
          </ListItem>
        ))}
      </List>
      <img src={SloganImage} alt={'GENIUSEE'} style={{ width: '312px', borderRadius: '16px' }} />
    </Section>
  );
}
