import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { ProjectData } from 'api/types';
import { API_PROJECTS, API_USERS } from 'constants/apiUrl';
import { UserRelations } from './get-one';

export type GetProjectUsersResponse = ProjectData & Pick<UserRelations, 'users'>;

async function getOneProjectUsers(projectId: string) {
  const response = await axiosInstance.get<GetProjectUsersResponse>(API_PROJECTS + '/' + projectId + '/' + API_USERS);
  return response.data;
}

export const getProjectUsersQueryKey = (projectId: string, refreshKey: number = 0) => [
  'project-get-users',
  projectId,
  refreshKey,
];

export function useProjectGetUsersQuery(projectId: string, enabled: boolean = true, refreshKey: number = 0) {
  return useQuery({
    queryKey: getProjectUsersQueryKey(projectId, refreshKey),
    queryFn: () => getOneProjectUsers(projectId),
    enabled: !!projectId && enabled,
    refetchOnWindowFocus: false,
  });
}
