import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'api/axios/instance';
import { API_PROJECTS_SYNC, API_RESET_DB } from 'constants/apiUrl';

export function useProjectsSync() {
  return useMutation({
    mutationKey: ['sync-projects'],
    mutationFn() {
      return axiosInstance.post(API_PROJECTS_SYNC);
    },
  });
}

export function useResetDb() {
  return useMutation({
    mutationKey: ['reset-db'],
    mutationFn() {
      return axiosInstance.post(API_RESET_DB);
    },
  });
}
