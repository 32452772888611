export const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const API_AUTH_LOG_IN = 'auth/login';
export const API_AUTH_LOG_OUT = 'auth/logout';
export const API_AUTH_REFRESH_TOKEN = 'auth/refresh-token';
export const API_AUTH_RESET_PASSWORD = 'auth/reset-password';
export const API_AUTH_CONFIRM_PASSWORD = 'auth/confirm-password';
export const API_AUTH_COMPLETE_SIGNUP = 'auth/complete-signup';

export const API_USERS = 'users';
export const API_USERS_ME = 'users/me';
export const API_USERS_INVITE = 'users/invite';
export const API_USERS_RESEND_INVITE = 'users/resend-invite';

export const API_PROJECTS = 'projects';
export const API_PROJECTS_SYNC = 'settings/sync-erp';
export const API_RESET_DB = 'settings/reset-db';

export const API_AGREEMENTS_HISTORY = 'agreements';

export const API_PROJECTS_SET_DESCRIPTION = 'projects/set-description';

export const API_PROJECTS_ADD_USER = 'projects/add-user';

export const API_CALENDAR = 'calendar';
