import Box from '@mui/material/Box';
import { TableVirtuoso } from 'react-virtuoso';
import { Button } from '@mui/material';
import generateVirtuosoTableComponents from 'components/table/virtuoso.common';
import generateHeaderContent from 'components/table/header-content';
import LoadingFooter from 'components/table/footer-content';
import { useProjectsSync, useResetDb } from 'api/mutations/projects/sync-projects';
import Wrap from 'components/table/Wrap';
import SearchInput from './components/SearchInput';
import { itemContent } from './components/RowContent';
import { columns } from './config';
import useProjectsTableData from './hooks/table-data';

const VirtuozoTableComponents = generateVirtuosoTableComponents(columns);
const fixedHeaderContent = generateHeaderContent(columns);

export default function SuperAdminProjectsTable() {
  const [filteredRows, isLoadingMore, loadMore, queryMeta] = useProjectsTableData();
  const { mutateAsync: syncProjects, isPending: isSyncPending } = useProjectsSync();
  const { mutateAsync: resetDb, isPending: isResetDbPending } = useResetDb();

  const handleSyncProjects = () => {
    syncProjects().then(() => {
      queryMeta.refetch();
    });
  };

  const handleResetDb = () => {
    resetDb().then(() => {
      queryMeta.refetch();
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box display={'flex'} gap={2}>
        <SearchInput />
        <Button onClick={() => handleSyncProjects()} variant={'contained'} disabled={isSyncPending}>
          {isSyncPending ? 'Synchronizing...' : 'Sync Projects'}
        </Button>
        <Button onClick={() => handleResetDb()} variant={'contained'} disabled={isResetDbPending}>
          {isResetDbPending ? 'Processing...' : 'Reset Database'}
        </Button>
      </Box>
      <Wrap noData={filteredRows.length === 0}>
        <TableVirtuoso
          fixedHeaderContent={fixedHeaderContent}
          fixedFooterContent={isLoadingMore ? LoadingFooter : undefined}
          itemContent={itemContent}
          data={filteredRows}
          components={{
            ...VirtuozoTableComponents,
            EmptyPlaceholder: isLoadingMore ? undefined : VirtuozoTableComponents.EmptyPlaceholder,
          }}
          atBottomStateChange={loadMore}
        />
      </Wrap>
    </Box>
  );
}
